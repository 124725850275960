import React, { useState, useEffect, useCallback } from "react";
import FormText from "../components/FormText.tsx";
import ApiService from "../Class/ApiService.ts";
import Cookies from "js-cookie";

interface ApiResponse<T = any> {
  user: User;
  profile: Profile;
  code: number;
  msg: string;
  token?: string;
  data?: T;
}

interface User {
  email: string;
  phone: string;
}

interface Profile {
  name: string;
  bank: string;
  bank_num: string;
}

const Infoafter: React.FC = () => {
  const [infoData, setInfoData] = useState({
    title: "",
    thumbnail: null as File | null, // 파일 업로드를 위한 상태
    link: "",
    ticket_num: "",
    want_count: "",
    tag: "",
  });

  const handleDataChange = (newData: string | File, id: string) => {
    if (infoData[id] !== newData) {
      setInfoData((prev) => ({ ...prev, [id]: newData }));
    }
  };

  const handleOnInfo = useCallback(async () => {
    try {
      const token = Cookies.get("token") || "";
      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");

      const response = await apiService.get<
        ApiResponse<{ user: User; profile: Profile }>
      >("/users/my_market/", undefined, token);

      if (response.data.code === 200) {
        setInfoData({
          title: response.data.profile.name || "",
          thumbnail: null, // 초기에는 파일을 비워둡니다.
          link: response.data.user.phone || "",
          ticket_num: response.data.profile.bank || "",
          want_count: response.data.profile.bank_num || "",
          tag: response.data.profile.bank_num || "",
        });
        console.log(infoData);
      } else {
        handleUpdateInfoResponse(response.data);
      }
    } catch (error) {
      console.error("정보 조회 중 오류 발생:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleOnInfo();
  }, [handleOnInfo]);

  const handleUpdateInfo = async (): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append("title", infoData.title);
      if (infoData.thumbnail) {
        formData.append("thumbnail", infoData.thumbnail);
      }
      formData.append("link", infoData.link);
      formData.append("ticket_num", infoData.ticket_num);
      formData.append("want_count", infoData.want_count);
      formData.append("tag", infoData.tag);

      const token = Cookies.get("token") || "";

      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");
      const response = await apiService.post<ApiResponse>(
        "/users/market_setting/",
        formData,
        token
      );

      handleUpdateInfoResponse(response.data);
    } catch (error) {
      console.error("정보 업데이트 중 오류 발생:", error);
    }
  };

  const handleUpdateInfoResponse = (responseData: ApiResponse) => {
    if (responseData.code === 200) {
      alert(responseData.msg);
      console.log(responseData);
    } else if (responseData.code === 300) {
      alert(responseData.msg);
      window.location.replace("/");
    } else {
      console.log(responseData.msg);
    }
  };

  return (
    <div className="mx-auto w-1/2 flex flex-col gap-16 m-20">
      <h1 className="self-center text-3xl font-bold">채널 정보</h1>
      <section className="grid grid-cols-1 py-8 gap-4">
        <FormText
          type={"input"}
          id={"title"}
          edit={true}
          title={"채널"}
          initialValue={infoData.title}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"file"}
          id={"thumbnail"}
          edit={true}
          title={"썸네일"}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"input"}
          id={"link"}
          edit={true}
          title={"채널 url"}
          initialValue={infoData.link}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"input"}
          id={"ticket_num"}
          edit={true}
          title={"총 발행수량"}
          initialValue={infoData.ticket_num}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"input"}
          id={"want_count"}
          edit={true}
          title={"개당 희망 액면가"}
          initialValue={infoData.want_count}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"input"}
          id={"tag"}
          edit={true}
          title={"희망 태그"}
          initialValue={infoData.tag}
          onDataChange={handleDataChange}
        />
      </section>
      <button
        className="w-full p-2 text-base text-gray-50 bg-brand rounded-full border-2 border-brand hover:opacity-90"
        onClick={handleUpdateInfo}
      >
        신청하기
      </button>
    </div>
  );
};

export default Infoafter;
