// 마이페이지 - 실현 손익
import React, { useState, useEffect, useCallback } from "react";
import AmountCard from "../components/AmountCard.tsx";
import Table from "../components/Table.tsx";
import ApiService from "../Class/ApiService.ts";
import Cookies from "js-cookie";

// API 응답 인터페이스 정의
interface ApiResponse<T = any> {
  real_profit: number;
  dividend: number;
  sell_amount: number;
  posts: any[];
  code: number;
  msg: string;
  token?: string;
  data?: T;
}

const MypageProfit: React.FC = () => {
  // 상태 관리
  const [realProfit, setRealProfit] = useState<number>(0);
  const [dividend, setDividend] = useState<number>(0);
  const [sellAmount, setSellAmount] = useState<number>(0);
  const [pointData, setPointData] = useState<any[]>([]);

  // 데이터 가져오기 함수
  const fetchData = useCallback(async () => {
    try {
      const token = Cookies.get("token") || "";
      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");

      const response = await apiService.get<ApiResponse>(
        "/users/my_realized_profit/",
        undefined,
        token
      );

      if (response.data.code === 200) {
        // 상태 업데이트
        setRealProfit(response.data.real_profit);
        setDividend(response.data.dividend);
        setSellAmount(response.data.sell_amount);
        setPointData(response.data.posts);
        console.log(response.data);
      } else {
        handleErrorResponse(response.data);
      }
    } catch (error) {
      console.error("정보 조회 중 오류 발생:", error);
    }
  }, []);

  // 에러 처리 함수
  const handleErrorResponse = (responseData: ApiResponse) => {
    if (responseData.code === 300) {
      alert(responseData.msg);
      window.location.replace("/");
    } else {
      console.log(responseData.msg);
    }
  };

  // 컴포넌트가 마운트될 때 데이터 가져오기
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="mx-auto w-4/5 flex flex-col gap-3 my-10">
      <h1 className="self-center text-3xl font-bold">실현손익</h1>
      <h2 className="mt-10 text-3xl font-bold">실현손익 (누적)</h2>
      <section className="grid grid-cols-1 gap-4 py-8 sm:grid-cols-3">
        <AmountCard type="amount" text={`판매(원)`} amount={sellAmount} />
        <AmountCard type="total" profit={realProfit} benefit={dividend} />
        <AmountCard
          type="amount"
          text={`총 실현 손익(실현손익 + 배당금)`}
          amount={realProfit + dividend}
        />
      </section>
      <h3 className="mt-10 text-3xl font-bold">배당 완료 채널</h3>
      <section className="grid grid-cols-1 py-8 gap-4">
        <Table type={"profit"} bodyData={pointData} />
      </section>
    </div>
  );
};

export default MypageProfit;
