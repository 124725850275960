import React from "react";

interface AmountCardProps {
  type: "total" | "amount";
  text?: string;
  amount?: number;
  profit?: number;
  benefit?: number;
}

const formatAmount = (amount: number): string => {
  return amount.toLocaleString("en-US"); // 3자리마다 쉼표를 추가하여 포맷팅
};

const AmountCard: React.FC<AmountCardProps> = React.memo(
  ({ type, text, amount, profit, benefit }) => {
    if (type === "amount") {
      const displayedAmount = amount !== undefined ? amount : 0;
      const textColorClass =
        text === "구매(원)"
          ? "text-black"
          : displayedAmount > 0
          ? "text-red-500"
          : displayedAmount < 0
          ? "text-blue-500"
          : "text-black";

      return (
        <div className="w-full flex flex-col justify-center items-center p-4 rounded-xl shadow-xl place-items-start min-h-60">
          <div className="flex flex-col justify-center items-center gap-y-3">
            <h1 className="whitespace-pre-line break-all text-xl">{text}</h1>
            <p
              className={`whitespace-pre-line break-all text-2xl font-semibold ${textColorClass}`}
            >
              {amount !== undefined ? formatAmount(amount) : "-"} 원
            </p>
          </div>
        </div>
      );
    }

    if (type === "total") {
      return (
        <div className="w-full flex flex-col justify-center items-center p-4 rounded-xl shadow-xl place-items-start min-h-60">
          <div className="w-4/5 flex flex-col justify-center items-center gap-y-3">
            <div className="row-span-1 w-full flex flex-row justify-between items-center">
              <h1 className="text-lg">실현손익</h1>
              <p className="text-lg">
                {profit !== undefined ? formatAmount(profit) : "-"} 원
              </p>
            </div>
            <div className="row-span-1 w-full flex flex-row justify-between items-center">
              <h1 className="text-lg">배당금</h1>
              <p className="text-lg">
                {benefit !== undefined ? formatAmount(benefit) : "-"} 원
              </p>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
);

export default AmountCard;
