import ApiService from "./ApiService.ts";
import Cookies from "js-cookie";

interface ApiResponse<T = any> {
  code: number;
  msg: string;
  token?: string;
  data?: T;
}

export default class SocialLogin {
  social: "google" | "kakao" | "naver" | "logout";
  clientId: string;
  redirectUri: string;
  state: string;
  apiService: ApiService;

  constructor(social: "google" | "kakao" | "naver" | "logout") {
    this.social = social;
    this.clientId = this.getClientId();
    this.redirectUri = this.getRedirectUri();
    this.state = this.generateState();

    const potenURL = process.env.REACT_APP_POTEN_URL || "";
    this.apiService = new ApiService(potenURL);
  }

  private getClientId(): string {
    return (
      process.env[`REACT_APP_${this.social.toUpperCase()}_CLIENT_ID`] || ""
    );
  }

  private getRedirectUri(): string {
    const baseUrl =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_REDIRECT_URI_PRODUCTION
        : process.env.REACT_APP_REDIRECT_URI_DEVELOPMENT;

    return `${baseUrl}/${this.social}`;
  }

  private generateState(): string {
    return Math.random().toString(36).substring(2);
  }

  private getAuthUrl(): string {
    const authUrls: Record<typeof this.social, string> = {
      google: `https://accounts.google.com/o/oauth2/v2/auth`,
      kakao: `https://kauth.kakao.com/oauth/authorize`,
      naver: `https://nid.naver.com/oauth2.0/authorize`,
      logout: "",
    };

    const queryParams = new URLSearchParams({
      response_type: "code",
      client_id: this.clientId,
      redirect_uri: this.redirectUri,
      state: this.state,
    });

    if (this.social === "google") {
      queryParams.append("scope", "profile email");
    }

    return `${authUrls[this.social]}?${queryParams.toString()}`;
  }

  public login(): void {
    window.location.href = this.getAuthUrl();
  }

  public async handleRedirect(): Promise<void> {
    const code = new URL(window.location.href).searchParams.get("code");

    if (!code) {
      console.error("No code found in the URL.");
      return;
    }

    const formData = new FormData();
    formData.append("social", this.social);
    formData.append("code", code);

    console.log(`${this.social} login code:`, code);

    try {
      const response = await this.apiService.post<ApiResponse>(
        "users/sns_login/",
        formData,
        undefined
      );

      this.handleLoginResponse(response.data);
    } catch (error) {
      console.error("Error during social login:", error);
    }
  }

  private handleLoginResponse(responseData: ApiResponse) {
    if (responseData.code === 200) {
      Cookies.remove("token"); // 기존 쿠키 제거
      if (responseData.token) {
        Cookies.set("token", responseData.token, { expires: 7 }); // 7일 동안 유효한 쿠키 설정
        alert(responseData.msg);
        window.location.replace("/");
      } else {
        console.error("Token not found in the response.");
      }
    } else {
      alert(responseData.msg);
    }
  }

  public async logout(): Promise<void> {
    try {
      const token = Cookies.get("token");
      const response = await this.apiService.post<ApiResponse>(
        "/users/logout/",
        null,
        token || undefined
      );

      if (response.data?.code === 200) {
        Cookies.remove("token"); // 로그아웃 시 쿠키 제거
        alert("로그아웃 되었습니다.");
        window.location.replace("/login");
      } else {
        console.error("Logout failed:", response.data?.msg);
        alert(response.data?.msg || "로그아웃에 실패하였습니다.");
      }
    } catch (error) {
      console.error("Error during logout:", error);
      alert("로그아웃 중 오류가 발생하였습니다.");
    }
  }
}
