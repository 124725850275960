// QandA

import React from "react";
import TextContainer from "../components/TextContainer.tsx";



const QandA: React.FC = () => (
  <div className="mx-auto w-5/6 flex flex-col gap-16 m-20">
    <h1 className="self-center text-3xl font-bold">Q&A</h1>
    <section className="grid grid-cols-1 py-8 gap-4">
      <TextContainer shadow={false}/>
      <TextContainer shadow={false}/>
      <TextContainer shadow={false}/>
      <TextContainer shadow={false}/>
    </section>
  </div>
);

export default QandA;
