import React, { useState, useEffect, useCallback } from "react";
import Table from "../components/Table.tsx";
import ApiService from "../Class/ApiService.ts";

// API 응답 인터페이스 정의
interface ApiResponse<T = any> {
  point: number;
  posts: any[];
  code: number;
  msg: string;
  token?: string;
  data?: T;
}

const Notice: React.FC = () => {
  const [noticeData, setNoticeData] = useState<any[]>([]);

  // 공지사항 데이터를 가져오는 함수
  const fetchNoticeData = useCallback(async () => {
    try {
      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");
      const response = await apiService.get<ApiResponse>("/posts/notice_list/");

      if (response.data.code === 200) {
        setNoticeData(response.data.posts);
        console.log(response.data.data);
      } else {
        handleUpdateDataResponse(response.data);
      }
    } catch (error) {
      console.error("정보 조회 중 오류 발생:", error);
      alert("공지사항 데이터를 불러오는 중 오류가 발생했습니다.");
    }
  }, []);

  // API 응답에 따른 처리 함수
  const handleUpdateDataResponse = (responseData: ApiResponse) => {
    switch (responseData.code) {
      case 200:
        alert(responseData.msg);
        console.log(responseData);
        break;
      case 300:
        alert(responseData.msg);
        window.location.replace("/");
        break;
      default:
        console.error(responseData.msg);
    }
  };

  // 컴포넌트 마운트 시 데이터 가져오기
  useEffect(() => {
    fetchNoticeData();
  }, [fetchNoticeData]);

  return (
    <div className="mx-auto w-4/5 flex flex-col gap-3 m-10">
      <h1 className="self-center text-3xl font-bold">공지사항</h1>
      <section className="grid grid-cols-1 py-8 gap-4">
        <Table type="notice" bodyData={noticeData} />
      </section>
    </div>
  );
};

export default Notice;
