import React, { useState, useEffect, useCallback, useRef } from "react";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import Card from "../components/Card.tsx";
import ChartList from "../components/ChartList.tsx";
import ChartInfo from "../components/ChartInfo.tsx";
import ApiService from "../Class/ApiService.ts";

interface ApiResponse<T = any> {
  code: number;
  msg: string;
  token?: string;
  data?: T;
  posts: any[];
}

const Market: React.FC = () => {
  const sliderRef = useRef<Slider>(null);
  const [priceType, setPriceType] = useState<number>(0);
  const [amountType, setAmountType] = useState<number>(0);
  const [priceData, setPriceData] = useState<any[]>([]);
  const [amountData, setAmountData] = useState<any[]>([]);
  const [hotYoutubersData, setHotYoutubersData] = useState<any[]>([]);
  const [newYoutubersData, setNewYoutubersData] = useState<any[]>([]);

  const HOT_YOUTUBERS_TYPE = 8;
  const NEW_YOUTUBERS_TYPE = 9;

  const fetchAndSetData = useCallback(
    async (
      type: number,
      setData: React.Dispatch<React.SetStateAction<any[]>>
    ) => {
      try {
        const apiService = new ApiService(
          process.env.REACT_APP_POTEN_URL || ""
        );
        const formData = new FormData();
        formData.append("type", type.toString());

        const response = await apiService.post<ApiResponse>(
          "/posts/market_list_search/",
          formData,
          undefined
        );

        if (response.data.code === 200) {
          console.log(response.data.posts);
          setData(response.data.posts);
        } else {
          console.error("Error fetching data:", response.data.msg);
        }
      } catch (error) {
        console.error("정보 업데이트 중 오류 발생:", error);
      }
    },
    []
  );

  const fetchData = useCallback(async () => {
    await Promise.all([
      fetchAndSetData(priceType, setPriceData),
      fetchAndSetData(amountType, setAmountData),
      fetchAndSetData(HOT_YOUTUBERS_TYPE, setHotYoutubersData),
      fetchAndSetData(NEW_YOUTUBERS_TYPE, setNewYoutubersData),
    ]);
  }, [priceType, amountType, fetchAndSetData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <main className="mx-auto w-4/5 flex flex-col gap-y-8 my-20">
      <h1 className="p-3 text-2xl font-semibold sm:text-4xl">
        요즘 핫한 🔥 유튜버
      </h1>
      <section className="flex flex-row justify-between py-4">
        <button className="button text-3xl" onClick={previous}>
          <FaAngleLeft />
        </button>
        <div className="slider-container w-[95%]">
          <Slider ref={sliderRef} {...settings}>
            {hotYoutubersData.map((youtuber, index) => (
              <div key={index} className="p-4">
                <Card type={"youtubers"} channelData={youtuber} />
              </div>
            ))}
          </Slider>
        </div>
        <button className="button text-3xl" onClick={next}>
          <FaAngleRight />
        </button>
      </section>
      <h2 className="mt-8 p-3 text-2xl font-semibold sm:text-3xl">
        최근 추가된 유튜버
      </h2>
      <section className="grid grid-cols-1 pb-8 gap-4 lg:grid-cols-3">
        {newYoutubersData.map((youtuber, index) => (
          <Card
            key={index}
            type={"youtubers"}
            channelData={youtuber}
            bgColor={"bg-gray-200"}
          />
        ))}
      </section>
      <section className="grid grid-cols-1 justify-start items-start py-8 gap-10 xl:grid-cols-2">
        <ChartList type={"price"} bodyType={setPriceType}>
          {priceData.map((data, index) => (
            <ChartInfo
              key={index}
              type="ChartCard"
              rank={index + 1}
              channelData={data}
            />
          ))}
        </ChartList>
        <ChartList type={"amount"} bodyType={setAmountType}>
          {amountData.map((data, index) => (
            <ChartInfo
              key={index}
              type="ChartCard"
              rank={index + 1}
              channelData={data}
            />
          ))}
        </ChartList>
      </section>
    </main>
  );
};

export default Market;
