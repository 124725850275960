// src/index.js

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NotFound from "./pages/NotFound.tsx";
import Home from "./pages/Home.tsx";
import Login from "./pages/Login.tsx";
import RedirectHandler from "./components/RedirectHandler.tsx";
import MypageCh from "./pages/MypageCh.tsx";
import MypagePoint from "./pages/MypagePoint.tsx";
import MypageProfit from "./pages/MypageProfit.tsx";
import MypageTradeEdit from "./pages/MypageTradeEdit.tsx";
import InfoModify from "./pages/InfoModify.tsx";
import InfoBefore from "./pages/InfoBefore.tsx";
import Infoafter from "./pages/Infoafter.tsx";
import Notice from "./pages/Notice.tsx";
import NoticeDetail from "./pages/NoticeDetail.tsx";
import FAQ from "./pages/FAQ.tsx";
import QandA from "./pages/QandA.tsx";
import QandAForm from "./pages/QandAForm.tsx";
import Market from "./pages/Market.tsx";
import Marketdetail from "./pages/Marketdetail.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      { index: true, element: <Home /> },
      { path: "/login", element: <Login /> },
      { path: "/oauth/:service", element: <RedirectHandler /> },
      { path: "/mypageCh", element: <MypageCh /> },
      { path: "/mypagePoint", element: <MypagePoint /> },
      { path: "/mypageProfit", element: <MypageProfit /> },
      { path: "/mypageTradeEdit", element: <MypageTradeEdit /> },
      { path: "/infoModify", element: <InfoModify /> },
      { path: "/infoBefore", element: <InfoBefore /> },
      { path: "/infoafter", element: <Infoafter /> },
      { path: "/notice", element: <Notice /> },
      { path: "/noticeDetail", element: <NoticeDetail /> },
      { path: "/FAQ", element: <FAQ /> },
      { path: "/QandA", element: <QandA /> },
      { path: "/QandAForm", element: <QandAForm /> },
      { path: "/market", element: <Market /> },
      { path: "/marketdetail", element: <Marketdetail /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <RouterProvider router={router} />
  //<React.StrictMode>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals.console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
