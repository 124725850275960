import React, { useState, useEffect, useCallback } from "react";
import ChartInfo from "../components/ChartInfo.tsx";
import Marketmain from "../components/Marketmain.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import ApiService from "../Class/ApiService.ts";
import Cookies from "js-cookie";

interface ApiResponse<T = any> {
  code: number;
  msg: string;
  token?: string;
  data?: T;
  posts: any[];
}

const Marketdetail: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [postID] = useState<number>(
    location.state.items.id || location.state.items.channel_id || 0
  );
  const [channelData, setChannelData] = useState<any>(
    location.state.items || {}
  );
  const [resulteData, setResulteData] = useState<any>({});
  const [error, setError] = useState<string | null>(null);
  const [selectedButton, setSelectedButton] = useState<
    "거래" | "시세" | "정보"
  >("거래");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!location.state || !location.state.items) {
      setError("Channel data not found in location.state.");
      console.error("Channel data not found in location.state");
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    }
  }, [location.state, navigate]);

  const handleButtonClick = (buttonName: "거래" | "시세" | "정보") => {
    setSelectedButton(buttonName);
  };

  const fetchAndSetData = useCallback(
    async (
      post_id: number,
      setData: React.Dispatch<React.SetStateAction<any>>,
      url: string
    ) => {
      const token = Cookies.get("token") || "";
      try {
        const apiService = new ApiService(
          process.env.REACT_APP_POTEN_URL || ""
        );
        const formData = new FormData();
        formData.append("post_id", post_id.toString());

        const response = await apiService.post<ApiResponse>(
          url,
          formData,
          token
        );

        if (response.data.code === 200) {
          setData(response.data);
        } else {
          console.error("Error fetching data:", response.data.msg);
        }
      } catch (error) {
        console.error("정보 업데이트 중 오류 발생:", error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const url = (() => {
      switch (selectedButton) {
        case "거래":
          return "/posts/market_info/";
        case "시세":
          return "/posts/market_quote/";
        case "정보":
          return "/posts/market_detail_info/";
        default:
          return "/";
      }
    })();
    await fetchAndSetData(postID, setResulteData, url);
  }, [fetchAndSetData, selectedButton, postID]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <main className="mx-auto w-4/5 flex flex-col gap-y-8 my-20">
      <section className="flex flex-row justify-between py-4">
        <ChartInfo type="ChartDetail" channelData={channelData} />
      </section>
      <section className="flex flex-col justify-center items-center gap-y-8">
        <div className="w-full overflow-x-auto">
          <ul className="flex flex-row justify-start items-center w-full">
            <div className="self-end flex flex-row border-b border-brandbright" />
            {["거래", "시세", "정보"].map((buttonName) => (
              <React.Fragment key={buttonName}>
                <div className="self-end w-4 border-b border-brandbright" />
                <button
                  onClick={() =>
                    handleButtonClick(buttonName as "거래" | "시세" | "정보")
                  }
                  className={`w-60 p-1 font-semibold text-xl rounded-md rounded-b-none ${
                    selectedButton === buttonName
                      ? "bg-white text-brandbright border border-brandbright border-b-white"
                      : "bg-gray-100 text-gray-500 border border-b-brandbright border-gray-300"
                  }`}
                >
                  <li>{buttonName}</li>
                </button>
              </React.Fragment>
            ))}
            <div className="self-end flex-grow border-b border-brandbright" />
          </ul>
        </div>
        {isLoading ? (
          <div>로딩 중...</div>
        ) : (
          <Marketmain
            type={selectedButton}
            bodyData={resulteData}
            channelData={channelData}
          />
        )}
      </section>
    </main>
  );
};

export default Marketdetail;
