import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar.tsx";
import Footer from "./components/Footer.tsx";
import SocialLogin from "./Class/SocialLogin.ts";
import { Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const AUTO_LOGOUT_TIME = 30 * 60 * 1000; // 30분

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleActivity = () => {
      localStorage.setItem("lastActivity", Date.now().toString());
    };

    const checkActivity = async () => {
      const lastActivity = parseInt(
        localStorage.getItem("lastActivity") || "0",
        10
      );
      if (Date.now() - lastActivity > AUTO_LOGOUT_TIME) {
        if (Cookies.get("token")) {
          try {
            const socialLogin = new SocialLogin("logout");
            await socialLogin.logout();
            navigate("/login", { replace: true });
          } catch (error) {
            console.error("로그아웃 중 오류 발생:", error);
            alert("로그아웃 중 오류가 발생했습니다. 다시 시도해주세요.");
          }
        }
      }
    };

    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("keypress", handleActivity);
    document.addEventListener("mousedown", handleActivity);
    document.addEventListener("touchstart", handleActivity);

    const intervalId = setInterval(checkActivity, 10000);

    return () => {
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("keypress", handleActivity);
      document.removeEventListener("mousedown", handleActivity);
      document.removeEventListener("touchstart", handleActivity);
      clearInterval(intervalId);
    };
  }, [navigate]);

  return (
    <div className="flex flex-col max-w-[1920px] min-h-screen overflow-x-hidden">
      <Navbar />
      <main className="flex-grow">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default App;
