import React, { useState, useEffect, useCallback } from "react";
import Table from "../components/Table.tsx";
import ApiService from "../Class/ApiService.ts";
import Cookies from "js-cookie";

// API 응답 인터페이스 정의
interface ApiResponse<T = any> {
  posts: any[];
  code: number;
  msg: string;
  token?: string;
  data?: T;
}

const MypageTradeEdit: React.FC = () => {
  // 상태 관리
  const [selectedButton, setSelectedButton] = useState<
    "구매 신청" | "판매 신청"
  >("구매 신청");
  const [tradeData, setTradeData] = useState<any[]>([]);
  const buttons = ["구매 신청", "판매 신청"] as const; // 버튼 배열을 상수로 정의

  const handleButtonClick = (buttonName: "구매 신청" | "판매 신청") => {
    setSelectedButton(buttonName);
  };

  const fetchData = useCallback(async (): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append(
        "type",
        (selectedButton === "구매 신청" ? 0 : 1).toString()
      ); // 숫자를 문자열로 변환

      const token = Cookies.get("token") || "";

      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");
      const response = await apiService.post<ApiResponse>(
        "/users/my_transactions/",
        formData,
        token
      );

      fetchDataResponse(response.data);
    } catch (error) {
      console.error("정보 업데이트 중 오류 발생:", error);
    }
  }, [selectedButton]);

  const fetchDataResponse = (responseData: ApiResponse) => {
    if (responseData.code === 200) {
      setTradeData(responseData.posts); // 상태 업데이트
      console.log(responseData);
    } else if (responseData.code === 300) {
      alert(responseData.msg);
    } else {
      console.log(responseData.msg);
    }
  };

  // selectedButton이 변경될 때마다 데이터 가져오기
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="flex flex-col gap-16 m-20">
      <h1 className="self-center text-3xl font-bold">내 거래 관리</h1>
      <div className="flex flex-row justify-center items-center gap-x-4">
        {buttons.map((buttonName) => (
          <React.Fragment key={buttonName}>
            <button
              onClick={() => handleButtonClick(buttonName)}
              className={`w-1/3 p-3 text-xl ${
                selectedButton === buttonName
                  ? "text-gray-50 bg-brand rounded-lg border-2 border-brand"
                  : "text-gray-900 rounded-lg border-2 border-brand"
              }`}
            >
              {buttonName}
            </button>
          </React.Fragment>
        ))}
      </div>
      <section className="grid grid-cols-1 py-8 gap-4">
        <Table type={"trade"} bodyData={tradeData} />
      </section>
    </div>
  );
};

export default MypageTradeEdit;
