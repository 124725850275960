import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const isValidDate = (dateString: string): boolean => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};

const NoticeDetail: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [noticeData, setNoticeData] = useState({
    title: "",
    created_at: "",
    contents: "",
    is_important: false,
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (location.state && location.state.items) {
      setNoticeData({
        title: location.state.items.title || "",
        created_at: location.state.items.created_at || "",
        contents: location.state.items.contents || "",
        is_important: location.state.items.is_important || false,
      });
    } else {
      setError("Notice data not found in location.state.");
      console.error("Notice data not found in location.state");
      // 일정 시간 후 이전 페이지로 리다이렉트
      setTimeout(() => {
        navigate(-1); // 이전 페이지로 리다이렉트
      }, 3000);
    }
  }, [location.state, navigate]);

  if (error) {
    return (
      <div className="mx-auto w-4/5 flex flex-col gap-3 m-10">
        <h1 className="self-center text-3xl font-bold">Error</h1>
        <p className="self-center text-base text-red-500">{error}</p>
        <p className="self-center text-base">You will be redirected back.</p>
      </div>
    );
  }

  return (
    <div className="mx-auto w-4/5 flex flex-col gap-3 m-10">
      <h1 className="self-center text-3xl font-bold">{noticeData.title}</h1>
      <p className="self-end text-base">
        {isValidDate(noticeData.created_at) ? (
          <time dateTime={noticeData.created_at} className="text-gray-500">
            {new Intl.DateTimeFormat("ko-KR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(noticeData.created_at))}
          </time>
        ) : (
          "Invalid date"
        )}
      </p>
      <main className="grid grid-cols-1 py-8 gap-4">
        <p className="whitespace-pre-line break-all text-xl">
          {noticeData.contents}
        </p>
      </main>
    </div>
  );
};

export default NoticeDetail;
