// FAQ

import React from "react";
import TextContainer from "../components/TextContainer.tsx";

const FAQ: React.FC = () => (
  <div className="mx-auto w-4/5 flex flex-col gap-16 m-20">
    <h1 className="self-center text-3xl font-bold">자주 묻는 질문</h1>
    <section className="grid grid-cols-1 py-8 gap-4">
      <TextContainer shadow={true}/>
      <TextContainer shadow={true}/>
      <TextContainer shadow={true}/>
      <TextContainer shadow={true}/>
    </section>
  </div>
);

export default FAQ;
