import React, { useCallback, useState } from "react";
import { FaBell } from "react-icons/fa6";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import MarketmainDetail from "./MarketmainDetail.tsx";

interface MarketProps {
  type: "거래" | "시세" | "정보";
  bodyData?: any;
  channelData?: any;
}

// 금액 포맷팅 함수
const formatAmount = (amount: number | undefined | null): string => {
  if (amount === undefined || amount === null) return "-";
  return amount.toLocaleString("en-US") + " 원";
};

const Marketmain: React.FC<MarketProps> = ({
  type,
  bodyData = {},
  channelData = {},
}) => {
  const [selectedButton, setSelectedButton] = useState<
    "구매" | "판매" | "정정" | "체결"
  >("구매");

  const yesterdayAmount = bodyData?.yesterday_amount || 0;

  const handleButtonClick = useCallback(
    (buttonName: "구매" | "판매" | "정정" | "체결") => {
      setSelectedButton(buttonName);
    },
    []
  );

  const renderTradeTable = useCallback(() => {
    console.log(bodyData);
    const sumQuantity = (is_sell: number) =>
      bodyData?.transaction_list
        ?.filter((item) => item.is_sell === is_sell)
        .reduce((acc, item) => acc + item.quantity, 0) || 0;

    const totalSellQuantity = sumQuantity(1);
    const totalBuyQuantity = sumQuantity(0);

    const renderTransactionRow = (
      item: any,
      isSell: boolean,
      index: number
    ) => (
      <div key={item.id || index} className="grid grid-cols-2">
        {isSell && <p>{item.quantity}</p>}
        <p
          className={`flex flex-row justify-center items-center gap-x-2 font-semibold text-xl py-2 border border-1 border-white ${
            item.amount === yesterdayAmount
              ? "text-black"
              : item.amount < yesterdayAmount
              ? "text-blue-400"
              : "text-red-400"
          } ${isSell ? "bg-blue-100" : "bg-red-100"}`}
        >
          {formatAmount(item.amount)} 원
          <span className="text-gray-500 text-sm opacity-60">
            {item.benefit_cal}%
          </span>
        </p>
        {!isSell && <p>{item.quantity}</p>}
      </div>
    );

    return (
      <section className="font-semibold text-xl py-2 grid grid-rows-11 grid-cols-3 w-full text-center">
        <h1>판매 수량</h1>
        <h2>가격</h2>
        <h3>구매 수량</h3>
        <div className="row-span-5 col-span-2">
          {bodyData?.transaction_list
            ?.filter((item) => item.is_sell === 1)
            .map((item, index) => renderTransactionRow(item, true, index))}
        </div>
        <div className="col-start-2 row-span-5 col-span-2">
          {bodyData?.transaction_list
            ?.filter((item) => item.is_sell === 0)
            .map((item, index) => renderTransactionRow(item, false, index))}
        </div>
        <div className="col-span-3 grid grid-cols-3 font-semibold text-xl py-2 border-b">
          <div>{totalSellQuantity}</div>
          <div>총수량</div>
          <div>{totalBuyQuantity}</div>
        </div>
      </section>
    );
  }, [bodyData, yesterdayAmount]);

  const buttonHeader = useCallback(() => {
    const buttons: Array<"구매" | "판매" | "정정" | "체결"> = [
      "구매",
      "판매",
      "정정",
      "체결",
    ];

    const getButtonStyles = (buttonName: "구매" | "판매" | "정정" | "체결") => {
      const baseStyles =
        "w-60 p-1 font-semibold text-xl rounded-md rounded-b-none";
      const selectedStyles =
        selectedButton === buttonName ? "border-b-white" : "";

      switch (selectedButton) {
        case "구매":
          return `${baseStyles} ${
            selectedButton === buttonName
              ? "bg-white text-red-500 border border-red-500"
              : "bg-gray-100 text-gray-500 border border-b-red-500"
          } ${selectedStyles}`;
        case "판매":
          return `${baseStyles} ${
            selectedButton === buttonName
              ? "bg-white text-blue-500 border border-blue-500"
              : "bg-gray-100 text-gray-500 border border-b-blue-500"
          } ${selectedStyles}`;
        case "정정":
          return `${baseStyles} ${
            selectedButton === buttonName
              ? "bg-white text-green-500 border border-green-500"
              : "bg-gray-100 text-gray-500 border border-b-green-500"
          } ${selectedStyles}`;
        case "체결":
          return `${baseStyles} ${
            selectedButton === buttonName
              ? "bg-white text-black border border-black"
              : "bg-gray-100 text-gray-500 border border-b-black"
          } ${selectedStyles}`;
        default:
          return baseStyles;
      }
    };

    const getBorderColor = () => {
      switch (selectedButton) {
        case "구매":
          return "border-red-500";
        case "판매":
          return "border-blue-500";
        case "정정":
          return "border-green-500";
        case "체결":
          return "border-black";
        default:
          return "border-gray-300";
      }
    };

    return (
      <ul className="flex flex-row justify-start items-center w-full">
        <div
          className={`self-end flex flex-row border-b ${getBorderColor()}`}
        />
        {buttons.map((buttonName) => (
          <React.Fragment key={buttonName}>
            <div className={`self-end w-4 border-b ${getBorderColor()}`} />
            <button
              onClick={() => handleButtonClick(buttonName)}
              className={getButtonStyles(buttonName)}
            >
              {buttonName}
            </button>
          </React.Fragment>
        ))}
        <div className={`self-end w-4 border-b ${getBorderColor()}`} />
      </ul>
    );
  }, [handleButtonClick, selectedButton]);

  const renderBody = useCallback(() => {
    switch (type) {
      case "거래":
        return (
          <div className="p-4 mx-auto w-full">
            <div className="grid grid-cols-10 gap-4">
              <div className="col-span-6 bg-white p-4">
                {renderTradeTable()}
                <div className="flex-col justify-center items-center py-4 font-semibold text-xl bg-white border-b-2 border-b-slate-200">
                  {/* 거래 정보 요약 */}
                  <div className="flex justify-between items-center mb-2">
                    <span>평균 구매가격</span>
                    <div className="flex items-center">
                      <span className="mx-2">
                        {bodyData?.my_list?.[0] || 0}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <span>보유 수량 (매도가능수량)</span>
                    <div className="flex items-center">
                      <span className="mx-2">
                        {bodyData?.my_list?.[1] || 0}주 (
                        {bodyData?.my_list?.[1] - bodyData?.my_list?.[3] || 0}주)
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <span>미체결</span>
                    <div className="flex justify-center items-center">
                      <span className="text-red-400 mx-2">
                        {bodyData?.my_list?.[2] || 0}
                        주(구매)
                      </span>
                      <div className="self-center w-0.5 h-4 bg-gray-300" />
                      <span className="text-blue-400 mx-2">
                        {bodyData?.my_list?.[3] || 0}
                        주(판매)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-4 bg-white p-4 border-b-2 border-b-slate-200">
                {buttonHeader()}
                <MarketmainDetail type={selectedButton} bodyData={bodyData} />
                <p className="mt-4 text-xs">
                  * 5주 이상 구매 주문 시 수수료가 0.8%로 감면됩니다.
                </p>
                <div className="bg-white">
                  <h2 className="text-xl font-bold mb-4 p-5 border-b-2 border-b-slate-200">
                    참여규정
                  </h2>
                  <ul className="whitespace-pre-line break-all px-5 text-xs font-semibold">
                    <li>
                      구매 및 판매건의 거래수수료는 각 1.0%이며 수수료는 매수한
                      금액에서 차감합니다.
                    </li>
                    <li>
                      구매 후 주식 수익률이 5% 이상 하락 시 자동 정리됩니다.
                    </li>
                    <li>
                      판매 주문 시 매도 가격이 5% 이상 상승 시 자동 정리됩니다.
                    </li>
                    <li>수수료는 매월 말일 기준으로 산정하여 청구됩니다.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      case "시세":
        const graphData = Array.isArray(bodyData.graph_data)
          ? bodyData.graph_data
          : [];
        const labels = graphData.map((item) =>
          item.date
            ? new Intl.DateTimeFormat("ko-KR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(item.date))
            : "Invalid date"
        );
        const prices = graphData.map((item) => item.last_price);

        return (
          <div className="w-full">
            <Line
              data={{
                labels: labels,
                datasets: [
                  {
                    label: "가격",
                    data: prices,
                    fill: false,
                    borderColor: "rgb(255, 99, 132)",
                    tension: 0.1,
                  },
                ],
              }}
            />
            <div className="max-h-96 overflow-y-auto">
              <table className="min-w-full h-32 divide-y divide-gray-200 mt-4">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      거래시간
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      가격
                    </th>
                    <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-end">
                      수량
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Array.isArray(bodyData.posts) &&
                    bodyData.posts.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.created_at
                            ? new Intl.DateTimeFormat("ko-KR", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }).format(new Date(item.created_at))
                            : "Invalid date"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {formatAmount(item.amount)} 원
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-end">
                          {item.quantity} 주
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      case "정보":
        console.log(bodyData);
        const data = Array.isArray(bodyData.posts) ? bodyData.posts : [];
        const labelsInfo = data.map((item) =>
          item.created_at
            ? new Intl.DateTimeFormat("ko-KR", {
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(item.created_at))
            : "Invalid date"
        );
        const pricesInfo = data.map((item) => item.amount);
        console.log(channelData);
        return (
          <div className="px-10 grid grid-cols-1 grid-rows-4 gap-x-40 gap-y-20 md:grid-cols-2 md:grid-rows-2">
            <div className="w-full p-8 flex flex-col gap-y-6 justify-center items-center rounded-xl shadow-xl">
              <div className="w-full flex justify-center align-middle">
                <span className="self-stretch w-28 h-28 bg-brandbright rounded-full" />
              </div>
              <h1 className="font-semibold text-3xl">{channelData.title}</h1>
              <a
                className="inline-block py-1.5 px-3 text-sm rounded-full text-white bg-brand hover:bg-brand-dark transition duration-200"
                href={channelData.link}
              >
                보러가기
              </a>
            </div>
            <div className="w-full p-8 flex flex-col gap-y-2 justify-center items-start rounded-xl shadow-xl">
              <h1 className="text-2xl">수익금 배당정보</h1>
              <div className="w-full flex justify-center align-middle">
                <Line
                  className="self-stretch w-full h-60"
                  data={{
                    labels: labelsInfo,
                    datasets: [
                      {
                        label: "가격",
                        data: pricesInfo,
                        fill: false,
                        borderColor: "rgb(255, 99, 132)",
                        tension: 0.1,
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className="w-full p-8 flex flex-col gap-y-2 justify-center items-start rounded-xl shadow-xl">
              <h1 className="text-2xl">수익증권 정보</h1>
              <div className="w-full flex flex-grow justify-center items-center rounded-xl border border-gray-300">
                <div className="w-full h-full p-8 flex flex-col justify-center items-stretch gap-y-2">
                  <h1 className="self-center text-3xl font-semibold">
                    {channelData.title}
                  </h1>
                  <div className="flex-grow flex flex-row justify-between items-center">
                    <h2>총 발행 수량</h2>
                    <p>{formatAmount(channelData.view_count)} 개</p>
                  </div>
                  <div className="flex-grow flex flex-row justify-between items-center">
                    <h2>액면가</h2>
                    <p>{formatAmount(channelData.now_count)}</p>
                  </div>
                  <div className="flex-grow flex flex-row justify-between items-center">
                    <h2>최초발행일</h2>
                    <p>
                      {channelData.created_at
                        ? new Intl.DateTimeFormat("ko-KR", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }).format(new Date(channelData.created_at))
                        : "Invalid date"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full p-8 flex flex-col gap-y-2 justify-start items-start rounded-xl shadow-xl">
              <div className="w-full max-h-80 overflow-y-auto">
                <h1 className="flex flex-row justify-start items-baseline text-2xl">
                  <FaBell />
                  알림
                </h1>
                {Array.isArray(bodyData.notice) &&
                  bodyData.notice.map((item, index) => (
                    <p
                      key={index}
                      className="whitespace-pre-line break-all flex-grow"
                    >
                      {item.contents}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyData, channelData, type, selectedButton]);

  return (
    <div className="mx-auto w-full max-w-2xl py-10 lg:mx-0 lg:max-w-none">
      {renderBody()}
    </div>
  );
};
export default Marketmain;
