import React from "react";
import { Link } from "react-router-dom";
import { MdComputer, MdHouse } from "react-icons/md";
import { CgTemplate } from "react-icons/cg";

const NotFound: React.FC = () => {
  return (
    <div className="mx-auto max-w-screen-2xl p-4 py-6 lg:py-8">
      <main className="grid min-h-full grow place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="mt-6 leading-7 font-semibold text-2xl text-brand">
            404
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            This page does not exist
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="p-4">
            <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-l leading-6 hover:bg-gray-50">
              <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <MdComputer className="text-brand text-xl" />
              </div>
              <div className="flex-auto">
                <Link to="#" className="block font-semibold text-gray-900">
                  Documentation
                  <span className="absolute inset-0"></span>
                </Link>
                <p className="mt-1 text-gray-600">
                  Learn how to integrate our tools with your app.
                </p>
              </div>
            </div>
            <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-l leading-6 hover:bg-gray-50">
              <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <MdHouse className="text-brand text-xl" />
              </div>

              <div className="flex-auto">
                <Link to="#" className="block font-semibold text-gray-900">
                  API Reference
                  <span className="absolute inset-0"></span>
                </Link>
                <p className="mt-1 text-gray-600">
                  A complete API reference for our libraries.
                </p>
              </div>
            </div>
            <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-l leading-6 hover:bg-gray-50">
              <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <CgTemplate className="text-brand text-xl" />
              </div>
              <div className="flex-auto">
                <Link to="#" className="block font-semibold text-gray-900">
                  Guides
                  <span className="absolute inset-0"></span>
                </Link>
                <p className="mt-1 text-gray-600">
                  Installation guides that cover popular setups.
                </p>
              </div>
            </div>
            <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-l leading-6 hover:bg-gray-50">
              <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <CgTemplate className="text-brand text-xl" />
              </div>
              <div className="flex-auto">
                <Link to="#" className="block font-semibold text-gray-900">
                  Blog
                  <span className="absolute inset-0"></span>
                </Link>
                <p className="mt-1 text-gray-600">
                  Read our latest news and articles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NotFound;
