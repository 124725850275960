import React from "react";
import { Link } from "react-router-dom";

interface CardProps {
  type: "main" | "channel" | "youtubers";
  channelData?: any;
  bgColor?: string; // 배경색을 지정하는 prop
}

const formatAmount = (amount: number | undefined): string => {
  if (amount === undefined) return "-";
  return amount.toLocaleString("en-US") + " 원";
};

const Card: React.FC<CardProps> = ({ type, channelData = {}, bgColor }) => {
  // 메인 페이지 카드

  if (type === "main") {

    return (
      <Link
        to="/marketdetail"
        className="w-full grid grid-rows-5 px-8 pt-8 gap-y-2 rounded-xl shadow-xl place-items-start"
        state={{ items: channelData }}
      >
        <div className="row-span-3 w-full flex justify-center items-center">
          <img
            src={`${process.env.REACT_APP_POTEN_URL}/media/${channelData.thumbnail}`}
            alt={`${channelData.title} thumbnail`}
            className="self-stretch w-36 h-36 rounded-full"
          />
        </div>
        <div className="row-span-1 flex flex-col">
          <h1 className="font-semibold text-lg">{channelData.title}</h1>
          <p className="text-xs">조회수 {channelData.view_count}</p>
        </div>
        <button
          className="row-span-1 py-1.5 px-3 text-sm rounded-full text-gray-50 bg-brand"
          onClick={(e) => {
            e.preventDefault(); // 부모 링크로의 기본 동작 방지
            window.open(channelData.link, "_blank");
          }}
        >
          투자하기
        </button>
      </Link>
    );
  }

  // Youtubers 카드
  if (type === "youtubers") {
    return (
      <Link
        to="/marketdetail"
        className={`w-full grid grid-rows-2 auto-rows-auto px-8 pt-8 gap-y-2 rounded-xl shadow-lg ${
          bgColor ? bgColor : ""
        } place-items-start`}
        state={{ items: channelData }}
      >
        <div className="row-span-1 w-full flex justify-center items-center">
          <img
            src={`${process.env.REACT_APP_POTEN_URL}/media/${channelData.thumbnail}`}
            alt={`${channelData.title} thumbnail`}
            className="self-stretch w-28 h-28 rounded-full sm:w-32 sm:h-32"
          />
        </div>
        <div className="row-span-1 self-stretch flex flex-col justify-center">
          <h1 className="font-semibold text-lg">{channelData?.title}</h1>
          {bgColor && (
            <p className="text-base">
              Current Price: {formatAmount(channelData?.now_count)}
            </p>
          )}
          <p className="text-base">
            Price Increase: {channelData?.expenses_cal}%
          </p>
          {!bgColor && (
            <>
              <p className="text-base">
                Trading Volume: {channelData?.transaction_val} shares
              </p>
              <p className="text-base">
                Investment Amount: {formatAmount(channelData?.now_count)}
              </p>
            </>
          )}
        </div>
      </Link>
    );
  }

  // 채널 카드
  if (type === "channel") {
    console.log(channelData);
    return (
      <Link
        to="/marketdetail"
        className="w-full grid grid-rows-5 px-6 pt-4 gap-y-2 rounded-xl border border-gray-300 place-items-start"
        state={{ items: channelData }}
      >
        <div className="row-span-2 w-full flex justify-center items-center">
          <img
            src={`${process.env.REACT_APP_POTEN_URL}/media/${channelData.thumbnail}`}
            alt={`${channelData.title} thumbnail`}
            className="w-28 h-28 rounded-full"
          />
        </div>
        <h1 className="row-span-1 w-full flex justify-center items-center pt-4 text-xl font-semibold">
          {channelData?.chnnel_name}
        </h1>
        <div className="row-span-1 w-full flex flex-row justify-between items-center">
          <h1 className="text-base font-semibold">
            현재가: {formatAmount(channelData?.now_amount)}
          </h1>
          <p className="text-base">
            구매 수량: {channelData?.quantity}
          </p>
        </div>
        <div className="row-span-1 w-full flex flex-row justify-between items-center">
          <h1 className="text-base">수익률: {channelData?.profit_val} %</h1>
          <p className="text-base">
            구매 가격: {formatAmount(channelData?.amount)}
          </p>
        </div>
      </Link>
    );
  }

  return null;
};

export default Card;
