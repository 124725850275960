import React from "react";

interface TextContainerProps {
  shadow: boolean;
}

const TextContainer: React.FC<TextContainerProps> = ({ shadow }) => {
  const containerClasses = `w-full grid grid-rows-5 p-4 gap-y-2 rounded-xl ${
    shadow ? "shadow-xl" : "border border-slate-300"
  } place-items-start`;

  return (
    <div className={containerClasses}>
      <h1 className="font-semibold text-2xl">John's Tech Reviews</h1>
      <p className="whitespace-pre-line break-all text-lg">1.2M Subscribers</p>
    </div>
  );
};

export default TextContainer;
