import React, { useState, useCallback } from "react";
import { FaAngleRight } from "react-icons/fa6";

interface ChartListProps {
  type: "price" | "amount";
  bodyType?: (index: number) => void; // 각 테이블의 데이터 타입에 따라 수정 가능
  children?: React.ReactNode;
}

const ChartList: React.FC<ChartListProps> = React.memo(
  ({ type, children, bodyType }) => {
    const [selectedButton, setSelectedButton] = useState<string>(
      type === "price" ? "가격상승 TOP" : "거래많은 곡"
    );

    const handleButtonClick = useCallback(
      (buttonName: string, index: number) => {
        setSelectedButton(buttonName);
        bodyType && bodyType(type === "price" ? index : index + 4);
      },
      [bodyType, type]
    );

    const renderHeader = () => {
      const buttons = type === "price" 
        ? ["가격상승 TOP", "가격하락 TOP", "가격상승률 TOP", "가격하락률 TOP"]
        : ["거래많은 곡", "거래적은 곡", "거래량상승", "거래량하락"];

      return (
        <ul className="flex flex-row gap-x-2">
          {buttons.map((buttonName, index) => (
            <li key={buttonName}>
              <button
                onClick={() => handleButtonClick(buttonName, index)}
                className={`p-1 font-semibold text-sm rounded-lg ${
                  selectedButton === buttonName
                    ? "bg-brandbright text-gray-50 border border-brandbright"
                    : "text-gray-500 border border-gray-500"
                }`}
              >
                {buttonName}
              </button>
            </li>
          ))}
        </ul>
      );
    };

    return (
      <div className="w-full flex flex-col justify-center items-start gap-3">
        <div className="w-full flex flex-row justify-between items-center">
          <h3 className="mt-8 text-2xl font-semibold sm:text-3xl">
            {type === "price" ? "가격 정보" : "거래량 정보"}
          </h3>
          <button className="self-end flex flex-row justify-center items-center text-lg text-gray-500 font-semibold">
            더보기 <FaAngleRight />
          </button>
        </div>
        <div className="w-full overflow-x-auto">{renderHeader()}</div>
        <div className="w-full">{children}</div>
      </div>
    );
  }
);

export default ChartList;
