import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SocialLogin from "../Class/SocialLogin.ts";

const RedirectHandler: React.FC = () => {
  const { service } = useParams<{ service: string }>(); // 파라미터로부터 서비스 가져오기
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Service:", service); // 이 부분에서 service 값을 확인합니다.
    const handleLoginRedirect = async () => {
      try {
        if (service) {
          const loginService = new SocialLogin(
            service as "google" | "kakao" | "naver"
          );
          await loginService.handleRedirect();
          navigate("/");
        } else {
          throw new Error("Service is not defined.");
        }
      } catch (error) {
        console.error("소셜 로그인 처리 중 오류 발생:", error);
        alert("소셜 로그인 처리 중 오류 발생했습니다.");
        navigate("/");
      }
    };

    handleLoginRedirect();
  }, [service, navigate]);

  return (
    <div className="mt-48 min-h-screen flex justify-center items-center">
      <div>Loading...</div>
    </div>
  );
};

export default RedirectHandler;
