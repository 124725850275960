import React, { useState, useEffect, useCallback } from "react";
import Slider from "react-slick";
import Card from "../components/Card.tsx";
import ApiService from "../Class/ApiService.ts";
import { Link } from "react-router-dom";

interface ApiResponse<T = any> {
  code: number;
  msg: string;
  token?: string;
  data?: T;
  posts: any[];
}

const Home: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true,
    appendDots: (dots) => (
      <div className="relative">
        <ul className="absolute bottom-9 w-[99vw]"> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div className="backdrop-opacity-10 w-3 h-3 z-10 rounded-full bg-gray-200" />
    ),
  };

  const [hotYoutubersData, setHotYoutubersData] = useState<any[]>([]);
  const HOT_YOUTUBERS_TYPE = 8;

  const fetchAndSetData = useCallback(
    async (
      type: number,
      setData: React.Dispatch<React.SetStateAction<any[]>>
    ) => {
      try {
        const apiService = new ApiService(
          process.env.REACT_APP_POTEN_URL || ""
        );
        const formData = new FormData();
        formData.append("type", type.toString());

        const response = await apiService.post<ApiResponse>(
          "/posts/market_list_search/",
          formData,
          undefined
        );

        if (response.data.code === 200) {
          setData(response.data.posts);
          console.log(response.data);
        } else {
          console.error("데이터 가져오는 중 오류 발생:", response.data.msg);
        }
      } catch (error) {
        console.error("정보 업데이트 중 오류 발생:", error);
      }
    },
    []
  );

  const fetchData = useCallback(async () => {
    await fetchAndSetData(HOT_YOUTUBERS_TYPE, setHotYoutubersData);
  }, [fetchAndSetData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="grid grid-rows-3 grid-cols-3 gap-4">
      <section className="row-span-1 col-span-3">
        <Slider {...settings}>
          <div className="bg-red-200">
            <div className="flex flex-col justify-center items-center gap-y-5 min-h-[33vh]">
              <h1 className="font-bold text-4xl">
                TubeInvest에 오신 것을 환영합니다
              </h1>
              <p>좋아하는 유튜버에게 투자하고 수익을 추적하세요.</p>
              <button className="py-1.5 px-4 rounded-full bg-red-600 text-gray-50">
                시작하기
              </button>
            </div>
          </div>
          <div className="bg-red-300">
            <div className="flex flex-col justify-center items-center gap-y-5 min-h-[33vh]">
              <h1 className="font-bold text-4xl">
                TubeInvest에 오신 것을 환영합니다
              </h1>
              <p>좋아하는 유튜버에게 투자하고 수익을 추적하세요.</p>
              <button className="py-1.5 px-4 rounded-full bg-red-600 text-gray-50">
                시작하기
              </button>
            </div>
          </div>
          <div className="bg-red-400">
            <div className="flex flex-col justify-center items-center gap-y-5 min-h-[33vh]">
              <h1 className="font-bold text-4xl">
                TubeInvest에 오신 것을 환영합니다
              </h1>
              <p>좋아하는 유튜버에게 투자하고 수익을 추적하세요.</p>
              <button className="py-1.5 px-4 rounded-full bg-red-600 text-gray-50">
                시작하기
              </button>
            </div>
          </div>
        </Slider>
      </section>
      <section className="mx-auto w-4/5 py-8 row-span-1 col-span-3 grid grid-cols-1 gap-4 sm:grid-cols-3">
        {hotYoutubersData.slice(0, 3).map((hotData) => (
          <Card key={hotData.id} type={"main"} channelData={hotData} />
        ))}
      </section>
      <section className="row-span-1 col-span-3">
        <div className="flex flex-col justify-center items-center gap-y-5 min-h-[33vh]">
          <h1 className="font-bold text-4xl">투자 시작할 준비 되셨나요?</h1>
          <p>TubeInvest에 가입하고 좋아하는 유튜버를 지원하세요.</p>
          <Link to={'/market'} className="py-1.5 px-4 rounded-full bg-red-600 text-gray-50">
            시작하기
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home;
