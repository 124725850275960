import React, { useEffect, useState, useCallback } from "react";

interface FormTextProps {
  type: "input" | "area" | "select" | "file";
  title?: string;
  id: string;
  edit: boolean;
  placeholder?: string;
  onDataChange: (value: string | File, id: string) => void;
  initialValue?: string | File;
  options?: string[];
}

const FormText: React.FC<FormTextProps> = React.memo(
  ({
    type,
    title,
    id,
    edit,
    placeholder,
    onDataChange,
    initialValue = "",
    options = [],
  }) => {
    const [value, setValue] = useState<string | File>(initialValue);
    const [fileName, setFileName] = useState<string>(
      initialValue instanceof File ? initialValue.name : ""
    );

    // initialValue가 변경되었을 때만 value를 업데이트
    useEffect(() => {
      // initialValue와 현재 value가 다를 때만 업데이트
      if (initialValue !== value) {
        setValue(initialValue);
        if (initialValue instanceof File) {
          setFileName(initialValue.name);
        } else {
          setFileName("");
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
      onDataChange(value, id);
    }, [value, id, onDataChange]);

    const handleChange = useCallback(
      (
        e: React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      ) => {
        if (
          type === "file" &&
          e.target instanceof HTMLInputElement &&
          e.target.files
        ) {
          const file = e.target.files[0];
          setValue(file);
          setFileName(file.name);
        } else {
          setValue(e.target.value);
        }
      },
      [type]
    );

    const inputClassNames = `block flex-1 border-0 text-sm py-2 px-4 ${
      edit ? "bg-transparent" : "bg-gray-200/30"
    } text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-none sm:text-sm sm:leading-6`;

    const renderInputField = () => (
      <div className="flex flex-col justify-start items-stretch">
        {title && (
          <label
            htmlFor={id}
            className="block text-sm font-bold leading-6 text-gray-900"
          >
            {title}
          </label>
        )}
        <div className="mt-2">
          <div
            className={`flex ${
              type === "area" ? "rounded-xl" : "rounded-full"
            } shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-brand`}
          >
            {type === "input" && (
              <input
                type="text"
                name={id}
                id={id}
                autoComplete="on"
                className={inputClassNames}
                value={typeof value === "string" ? value : ""}
                onChange={handleChange}
                placeholder={placeholder}
                disabled={!edit}
              />
            )}
            {type === "area" && (
              <textarea
                name={id}
                id={id}
                rows={4}
                className={inputClassNames}
                value={typeof value === "string" ? value : ""}
                onChange={handleChange}
                placeholder={placeholder}
                disabled={!edit}
              />
            )}
            {type === "select" && (
              <select
                name={id}
                id={id}
                className={inputClassNames}
                value={typeof value === "string" ? value : ""}
                onChange={handleChange}
                disabled={!edit}
              >
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            )}
            {type === "file" && (
              <div className="flex items-center gap-2">
                <input
                  type="file"
                  name={id}
                  id={id}
                  className="hidden"
                  onChange={handleChange}
                  disabled={!edit}
                />
                <button
                  type="button"
                  className="p-2 text-base text-gray-700"
                  onClick={() => document.getElementById(id)?.click()}
                  disabled={!edit}
                >
                  파일 선택
                </button>
                {fileName && (
                  <span className="ml-4 text-gray-700">{fileName}</span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );

    return (
      <div className="mx-auto max-w-2xl w-full p-2 rounded-md lg:mx-0 lg:max-w-none">
        {renderInputField()}
      </div>
    );
  }
);

export default FormText;
