import React from "react";
import { Link } from "react-router-dom";

interface TableProps {
  type: "profit" | "trade" | "point" | "notice";
  bodyData?: any[]; // 각 테이블의 데이터 타입에 따라 수정 가능
}

const formatAmount = (amount: number | undefined): string => {
  if (amount === undefined || amount === null) {
    return "-"; // 값이 없을 때 표시할 기본 문자열
  }
  return amount.toLocaleString("en-US"); // 3자리마다 쉼표를 추가하여 포맷팅
};

const Table: React.FC<TableProps> = React.memo(({ type, bodyData }) => {
  const renderBody = () => {
    if (type === "profit") {
      return bodyData?.map((item, index) => (
        <tr key={index}>
          <td className="p-2 border-t border-slate-200">
            {item.channel_title}
          </td>
          <td className="p-2 border-t border-slate-200">
            {formatAmount(item.sell_amount)} 원
          </td>
          <td className="p-2 border-t border-slate-200">
            {formatAmount(item.real_profit)} 원
          </td>
          <td className="p-2 border-t border-slate-200">
            {formatAmount(item.dividend)} 원
          </td>
          <td className="p-2 border-t border-slate-200">
            <div className="flex items-center gap-x-4">
              <time dateTime={item.created_at} className="text-gray-500">
                {item.created_at
                  ? new Intl.DateTimeFormat("ko-KR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(item.created_at))
                  : "Invalid date"}
              </time>
            </div>
          </td>
        </tr>
      ));
    }
    if (type === "trade") {
      const fetchStatus = (status: number): string => {
        switch (status) {
          case 0:
            return "구매 예약";
          case 1:
            return "판매 예약";
          case 2:
            return "구매 체결";
          case 3:
            return "판매 체결";
          case 4:
            return "거래 취소";
          default:
            return "알 수 없음"; // 예상하지 못한 값에 대한 기본 반환값
        }
      };

      return bodyData?.map((item, index) => (
        <tr key={index}>
          <td className="p-2 border-t border-slate-200">
            {item.channel_title} - 수량 : {item.quantity}
          </td>
          <td className="p-2 border-t border-slate-200">
            {fetchStatus(item.is_sell)}
          </td>
          <td className="p-2 border-t border-slate-200">
            <div className="flex items-center gap-x-4">
              <time dateTime={item.created_at} className="text-gray-500">
                {item.created_at
                  ? new Intl.DateTimeFormat("ko-KR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(item.created_at))
                  : "Invalid date"}
              </time>
            </div>
          </td>
        </tr>
      ));
    }

    if (type === "point") {
      return bodyData?.map((item, index) => (
        <tr key={index}>
          <td className="p-2 border-t border-slate-200">{item.order_title}</td>
          <td className="p-2 border-t border-slate-200">
            {formatAmount(item.amount)} 포인트
          </td>
          <td className="p-2 border-t border-slate-200">
            <div className="flex items-center gap-x-4">
              <time dateTime={item.created_at} className="text-gray-500">
                {item.created_at
                  ? new Intl.DateTimeFormat("ko-KR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(item.created_at))
                  : "Invalid date"}
              </time>
            </div>
          </td>
        </tr>
      ));
    }
    if (type === "notice") {
      return bodyData?.map((item, index) => (
        <tr key={index}>
          <td className="p-2 border-t border-slate-200">
            <Link
              to={"/noticeDetail"}
              className="w-full"
              state={{ items: item }}
            >
              <p>{item.title}</p>
            </Link>
          </td>
          <td className="p-2 border-t border-slate-200">
            <div className="flex items-center gap-x-4">
              <time dateTime={item.created_at} className="text-gray-500">
                {item.created_at
                  ? new Intl.DateTimeFormat("ko-KR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(item.created_at))
                  : "Invalid date"}
              </time>
            </div>
          </td>
        </tr>
      ));
    }
    return null;
  };

  const renderHeader = () => {
    if (type === "profit") {
      return (
        <thead className="text-left">
          <tr>
            <th className="p-2">채널</th>
            <th className="p-2">판매금액</th>
            <th className="p-2">실현 손익</th>
            <th className="p-2">배당금</th>
            <th className="w-1/6 p-2">날짜</th>
          </tr>
        </thead>
      );
    }
    if (type === "trade") {
      return (
        <thead className="text-left">
          <tr>
            <th className="p-2">내용</th>
            <th className="p-2">상태</th>
            <th className="w-1/6 p-2">날짜</th>
          </tr>
        </thead>
      );
    }
    if (type === "point") {
      return (
        <thead className="text-left">
          <tr>
            <th className="p-2">내용</th>
            <th className="p-2">포인트</th>
            <th className="w-1/6 p-2">날짜</th>
          </tr>
        </thead>
      );
    }
    if (type === "notice") {
      return (
        <thead className="text-left">
          <tr>
            <th className="p-2">제목</th>
            <th className="w-1/6 p-2">날짜</th>
          </tr>
        </thead>
      );
    }
    return null;
  };

  return (
    <div className="mx-auto max-w-2xl w-full p-2 border border-slate-200 rounded-md lg:mx-0 lg:max-w-none">
      <table className="w-full border-spacing-0 border-b-slate-200 rounded-md">
        {renderHeader()}
        <tbody>{renderBody()}</tbody>
      </table>
    </div>
  );
});

export default Table;
