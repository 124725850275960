import React from "react";
import "../css/footer.css";
// import { FaFacebook, FaInstagram, FaBlogger, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="max-w-screen-2xl w-5/6 footer-inner flex flex-col justify-center sm:justify-between md:w-4/5">
        <div className="btn-group flex flex-row justify-between gap-x-3 ">
          <Link
            to="/privacy"
            className="footer-btn text-pretty whitespace-pre-line "
          >
            개인정보 처리방침
          </Link>
          <Link
            to="/terms"
            className="footer-btn text-pretty whitespace-pre-line"
          >
            이용약관
          </Link>
          <Link
            to="/guidelines"
            className="footer-btn text-pretty whitespace-pre-line "
          >
            사용자 유의사항
          </Link>
          <Link
            to="/paymentTerms"
            className="footer-btn text-pretty whitespace-pre-line "
          >
            결제환불약관
          </Link>
        </div>
        <div className="footer-profile flex flex-col justify-start gap-x-3 xl:flex-row">
          <span>주식회사 쉐릿 &nbsp;|&nbsp;</span>
          <span>사업자등록증 : 355-81-01039&nbsp;|&nbsp;</span>
          <span>대표자 : 김세영&nbsp;|&nbsp;</span>
          <span>대표자 이메일 : keynes30@naver.com&nbsp;|&nbsp;</span>
          <span>
            주소 : 전북 전주시 덕진구 만성북로 21-26, 304호&nbsp;|&nbsp;
          </span>
          <span>유선 전화 : 070 7798 2008</span>
        </div>
        {/* <div className="half-line flex flex-row justify-between">
          <div className="copyright">
            &copy;<span className="this-year"></span> Copyright Pulse AI. All
            Rights Reserved
          </div>
          <div className="sns-icon">
            <button
              href="https://www.facebook.com"
              className="fab fa-facebook facebook sns-icon"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook />
            </button>
            <button
              href="https://www.instagram.com/pulse.wave.official?igsh=dnFpc3ZwNW01bHo1"
              className="fab fa-instagram instagram sns-icon"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </button>
            <button
              href="https://section.blog.naver.com/"
              className="naver-blog sns-icon"
              target="_blank"
              rel="noreferrer"
            >
              <FaBlogger />
            </button>
            <button
              href="https://www.youtube.com"
              className="fab fa-youtube youtube sns-icon"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube />
            </button>
          </div>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
