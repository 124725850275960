import React from "react";
import SocialLogin from "../Class/SocialLogin.ts";

const Login: React.FC = () => {
  const handleGoogleLogin = () => {
    const googleLogin = new SocialLogin("google");
    googleLogin.login();
  };

  const handleKakaoLogin = () => {
    const kakaoLogin = new SocialLogin("kakao");
    kakaoLogin.login();
  };

  const handleNaverLogin = () => {
    const naverLogin = new SocialLogin("naver");
    naverLogin.login();
  };

  return (
    <div className="mx-auto w-1/3 flex flex-col gap-16 m-20">
      <h1 className="self-center text-3xl font-bold">로그인</h1>
      <section className="grid grid-cols-1 py-8 gap-4">
        <button
          className="w-full p-2 text-base text-gray-50 bg-green-400 rounded-full border-2 border-green-400 hover:opacity-90"
          onClick={handleNaverLogin}
        >
          네이버로 간편 회원가입 / 로그인
        </button>
        <button
          className="w-full p-2 text-base text-gray-50 bg-yellow-400 rounded-full border-2 border-yellow-400 hover:opacity-90"
          onClick={handleKakaoLogin}
        >
          카카오로 간편 회원가입 / 로그인
        </button>
        <button
          className="w-full p-2 text-base text-gray-50 bg-brand rounded-full border-2 border-brand hover:opacity-90"
          onClick={handleGoogleLogin}
        >
          Google로 간편 회원가입 / 로그인
        </button>
      </section>
    </div>
  );
};

export default Login;
