import React, { useState } from "react";
import FormText from "../components/FormText.tsx";
import ApiService from "../Class/ApiService.ts";
import Cookies from "js-cookie";

// API 응답 인터페이스 정의
interface ApiResponse<T = any> {
  user: any;
  profile: any;
  code: number;
  msg: string;
  token?: string;
  data?: T;
}

const InfoBefore: React.FC = () => {
  const [infoData, setInfoData] = useState({
    title: "",
    thumbnail: null as File | null,
    link: "",
    ticket_num: "",
    want_count: "",
    tag: "",
  });

  const handleDataChange = (newData: string | File, id: string) => {
    if (infoData[id] !== newData) {
      setInfoData((prev) => ({ ...prev, [id]: newData }));
    }
  };

  const handleUpdateInfo = async (): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append("title", infoData.title);
      if (infoData.thumbnail) {
        formData.append("thumbnail", infoData.thumbnail);
      }
      formData.append("link", infoData.link);
      formData.append("ticket_num", infoData.ticket_num);
      formData.append("want_count", infoData.want_count);
      formData.append("tag", infoData.tag);

      const token = Cookies.get("token") || "";

      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");
      const response = await apiService.post<ApiResponse>(
        "/users/market_setting/",
        formData,
        token
      );

      handleUpdateInfoResponse(response.data);
    } catch (error) {
      console.error("정보 업데이트 중 오류 발생:", error);
    }
  };

  const handleUpdateInfoResponse = (responseData: ApiResponse) => {
    if (responseData.code === 200) {
      alert(responseData.msg);
      console.log(responseData);
    } else if (responseData.code === 300) {
      alert(responseData.msg);
      window.location.replace("/");
    } else {
      console.log(responseData.msg);
    }
  };

  return (
    <div className="mx-auto w-1/2 flex flex-col gap-16 m-20">
      <h1 className="self-center text-3xl font-bold">채널 정보</h1>
      <section className="grid grid-cols-1 py-8 gap-4">
        <FormText
          type={"input"}
          id={"title"}
          edit={true}
          title={"채널"}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"file"}
          id={"thumbnail"}
          edit={true}
          title={"썸네일"}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"input"}
          id={"link"}
          edit={true}
          title={"채널 url"}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"input"}
          id={"ticket_num"}
          edit={true}
          title={"총 발행수량"}
          onDataChange={handleDataChange}
        />
        <FormText
          type={"input"}
          id={"want_count"}
          edit={true}
          title={
            "개당 희망 액면가 (해당 액면가는 관리자 확인 후 자동 조정됩니다)"
          }
          onDataChange={handleDataChange}
        />
        <FormText
          type={"input"}
          id={"tag"}
          edit={true}
          title={"희망 태그"}
          onDataChange={handleDataChange}
        />
      </section>
      <button
        className="w-full p-2 text-base text-gray-50 bg-brand rounded-full border-2 border-brand hover:opacity-90"
        onClick={handleUpdateInfo}
      >
        신청하기
      </button>
    </div>
  );
};

export default InfoBefore;
