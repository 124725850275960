import React, { useState, useEffect, useCallback } from "react";
import Table from "../components/Table.tsx";
import ApiService from "../Class/ApiService.ts";
import Cookies from "js-cookie";

// API 응답 인터페이스 정의
interface ApiResponse<T = any> {
  point: number;
  posts: any[];
  code: number;
  msg: string;
  token?: string;
  data?: T;
}


const MypagePoint: React.FC = () => {
  const [point, setPoint] = useState<number>(0);
  const [pointData, setPointData] = useState<any[]>([]);

  const handleOnData = useCallback(async () => {
    try {
      const token = Cookies.get("token") || "";
      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");

      const response = await apiService.get<ApiResponse>(
        "/users/my_point_log/",
        undefined,
        token
      );

      if (response.data.code === 200) {
        setPoint(response.data.point);
        setPointData(response.data.posts);
        console.log(response.data.data);
      } else {
        handleUpdateDataResponse(response.data);
      }
    } catch (error) {
      console.error("정보 조회 중 오류 발생:", error);
    }
  }, []);

  const handleUpdateDataResponse = (responseData: ApiResponse) => {
    if (responseData.code === 200) {
      alert(responseData.msg);
      console.log(responseData);
    } else if (responseData.code === 300) {
      alert(responseData.msg);
      window.location.replace("/");
    } else {
      console.log(responseData.msg);
    }
  };

  useEffect(() => {
    handleOnData();
  }, [handleOnData]);

  return (
    <div className="mx-auto w-4/5 flex flex-col gap-16 m-20">
      <div className="flex flex-col justify-start items-center gap-x-4 sm:flex-row">
        <p className="p-3 text-2xl font-semibold sm:text-4xl">
          현재 포인트 : {point.toLocaleString("en-US")}
        </p>
        <button className="w-40 p-1.5 text-center text-gray-50 bg-brand rounded-full border-2 border-brand hover:opacity-90">
          출금 신청
        </button>
      </div>
      <section className="grid grid-cols-1 py-8 gap-4">
        <Table type={"point"} bodyData={pointData} />
      </section>
    </div>
  );
};

export default MypagePoint;
