import React, { useState, useEffect, useCallback } from "react";
import Card from "../components/Card.tsx";
import AmountCard from "../components/AmountCard.tsx";
import ApiService from "../Class/ApiService.ts";
import Cookies from "js-cookie";

// API 응답 인터페이스 정의
interface ApiResponse<T = any> {
  total_purchase_amount: number;
  value_profit: number;
  last_month_point: number;
  posts: any[];
  code: number;
  msg: string;
  token?: string;
  data?: T;
}

const MypageCh: React.FC = () => {
  // 상태 관리
  const [totalPurchaseAmount, setTotalPurchaseAmount] = useState<number>(0);
  const [valueProfit, setValueProfit] = useState<number>(0);
  const [lastMonthPoint, setLastMonthPoint] = useState<number>(0);
  const [cardData, setCardData] = useState<any[]>([]);

  // 데이터 가져오기 함수
  const fetchData = useCallback(async () => {
    try {
      const token = Cookies.get("token") || "";
      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");

      const response = await apiService.get<ApiResponse>(
        "/users/my_channels/",
        undefined,
        token
      );

      if (response.data.code === 200) {
        // 상태 업데이트
        setTotalPurchaseAmount(response.data.total_purchase_amount);
        setValueProfit(response.data.value_profit);
        setLastMonthPoint(response.data.last_month_point);
        setCardData(response.data.posts);
        console.log(response.data);
      } else {
        handleErrorResponse(response.data);
      }
    } catch (error) {
      console.error("정보 조회 중 오류 발생:", error);
    }
  }, []);

  // 에러 처리 함수
  const handleErrorResponse = (responseData: ApiResponse) => {
    if (responseData.code === 300) {
      alert(responseData.msg);
      window.location.replace("/");
    } else {
      console.log(responseData.msg);
    }
  };

  // 컴포넌트가 마운트될 때 데이터 가져오기
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="mx-auto w-4/5 flex flex-col gap-3 m-10">
      <h1 className="self-center text-3xl font-bold">보유 채널 현황</h1>
      <h2 className="mt-10 text-3xl font-bold">총 보유 채널 : {cardData.length} 개</h2>
      <section className="grid grid-cols-1 gap-4 py-8 sm:grid-cols-3">
        <AmountCard
          type="amount"
          text="구매(원)"
          amount={totalPurchaseAmount}
        />
        <AmountCard
          type="amount"
          text="평가손익 (현재가 - 매입단가)"
          amount={valueProfit}
        />
        <AmountCard
          type="amount"
          text="전월 저작권료 수입"
          amount={lastMonthPoint}
        />
      </section>
      <h3 className="mt-10 text-3xl font-bold">보유 채널 목록</h3>
      <section className="grid grid-cols-1 py-8 gap-4 sm:grid-cols-3">
        {cardData.map((item, index) => (
          <Card
            key={index}
            type="channel"
            channelData={item} // API에서 받은 데이터 사용
          />
        ))}
      </section>
    </div>
  );
};

export default MypageCh;
