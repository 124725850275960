import React from "react";
import { FaCaretUp, FaCaretDown } from "react-icons/fa6";
import { Link } from "react-router-dom";

interface ChartInfoProps {
  type: "ChartCard" | "ChartDetail";
  rank?: number; // 순위
  channelData?: any;
}

const formatAmount = (amount: number | undefined): string => {
  if (amount === undefined) return "-";
  return amount.toLocaleString("en-US") + " 원";
};

const getColorClass = (type: string): string => {
  if (type === "priceUp") return "text-blue-500";
  if (type === "priceDown") return "text-red-500";
  return "text-gray-500";
};

const getIcon = (type: string) => {
  if (type === "priceUp") return <FaCaretUp />;
  if (type === "priceDown") return <FaCaretDown />;
  return null;
};

const ChartInfo: React.FC<ChartInfoProps> = ({
  type,
  rank,
  channelData = {},
}) => {
  const tagsArray = channelData.tag ? channelData.tag.split(",") : [];
  console.log(channelData);

  return type === "ChartCard" ? (
    <Link
      to="/marketdetail"
      className="my-2 w-full flex flex-row justify-between items-center hover:opacity-80"
      state={{ items: channelData }}
    >
      <div className="relative mr-2 w-20 h-20">
        <img
          src={`${process.env.REACT_APP_POTEN_URL}/media/${channelData.thumbnail}`}
          alt={`${channelData.title} thumbnail`}
          className="w-full h-full rounded-lg"
        />
        <span className="absolute top-0 left-0 w-4 h-4 z-10 flex flex-col justify-center items-center font-semibold opacity-80 rounded-md bg-gray-800 text-gray-100">
          {rank}
        </span>
      </div>
      <div className="flex-grow grid grid-rows-3">
        <div className="flex flex-row justify-between items-center">
          <h1 className="whitespace-nowrap overflow-hidden text-ellipsis w-2/5 font-bold">
            {channelData?.title} 채널
          </h1>
        </div>
        <div className="flex flex-row justify-between items-center">
          <h1 className="whitespace-nowrap overflow-hidden text-ellipsis w-2/5 font-bold">
            {formatAmount(channelData?.now_count)}
          </h1>
          <p
            className={`whitespace-nowrap text-ellipsis flex flex-row justify-end items-center w-2/5 font-bold ${getColorClass(
              type
            )}`}
          >
            {getIcon(type)}
            {formatAmount(channelData?.expenses)} (
            {channelData?.expenses_cal ? channelData?.expenses_cal : 0}%)
          </p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <h1 className="whitespace-nowrap overflow-hidden text-ellipsis w-2/5 text-xs font-bold text-gray-500">
            저작권료 (1주)
          </h1>
          <p
            className={`whitespace-nowrap text-ellipsis flex flex-row justify-end items-center w-2/5 text-sm font-bold text-gray-500`}
          >
            {formatAmount(channelData?.previous_count)} (
            {channelData?.previousYearRates
              ? channelData?.previousYearRates
              : 0}
            %)
          </p>
        </div>
      </div>
    </Link>
  ) : (
    <div className="my-2 w-full flex flex-col justify-start items-start gap-y-4 gap-x-8 md:flex-row">
      <div className="flex-grow flex flex-row gap-x-8">
        <img
          src={`${process.env.REACT_APP_POTEN_URL}/media/${channelData.title || channelData.channel_thumbnail}`}
          alt={`${channelData.title || channelData.channel_thumbnail} thumbnail`}
          className="w-28 h-28 rounded-lg md:w-40 md:h-40"
        />
        <div className="max-w-md w-3/4 flex flex-col justify-between gap-y-2">
          <h1 className="whitespace-nowrap overflow-hidden text-ellipsis w-full text-2xl font-bold">
            {channelData?.title}
          </h1>
          <div className="flex flex-row justify-between items-center gap-x-2 w-full">
            <h2 className="whitespace-nowrap overflow-hidden text-ellipsis font-bold text-gray-500">
              현재가
            </h2>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis flex flex-row justify-end items-center font-bold text-gray-900">
              {formatAmount(channelData?.now_count)}
            </p>
          </div>
          <div className="flex flex-row justify-between items-center gap-x-2">
            <h3 className="whitespace-nowrap overflow-hidden text-ellipsis font-bold text-gray-500">
              전달비
            </h3>
            <p
              className={`whitespace-nowrap overflow-hidden text-ellipsis flex flex-row justify-end items-center font-bold ${getColorClass(
                type
              )}`}
            >
              {getIcon(type)}
              {formatAmount(channelData?.expenses)} (
              {channelData?.expenses_cal ? channelData?.expenses_cal : 0}%)
            </p>
          </div>
          <div className="flex flex-row justify-between items-center gap-x-2">
            <h4 className="whitespace-pre overflow-hidden text-ellipsis text-base font-bold text-gray-500">
              과거 1년 저작권료 (1주)
            </h4>
            <p
              className={`whitespace-nowrap text-ellipsis flex flex-row justify-end items-center text-base font-bold text-gray-900`}
            >
              {formatAmount(channelData?.previous_count)} (
              {channelData?.previousYearRates
                ? channelData?.previousYearRates
                : 0}
              %)
            </p>
          </div>
        </div>
      </div>
      <div className="flex-grow flex flex-col justify-start items-start gap-y-2">
        <ul className="flex flex-row flex-wrap gap-2">
          {tagsArray.map((tag, index) => (
            <li key={index} className="text-xl text-brandbright">
              #{tag.trim()}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ChartInfo;
