import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import SocialLogin from "../Class/SocialLogin.ts"; // SocialLogin 클래스 경로
import { FaVideo, FaSistrix } from "react-icons/fa6";
import Cookies from "js-cookie";

const Navbar: React.FC = () => {
  // 온라인 상태 및 드롭다운 상태 관리
  const [online, setOnline] = useState(!!Cookies.get("token"));
  const [customerSupportDropdownOpen, setCustomerSupportDropdownOpen] = useState(false);
  const [myPageDropdownOpen, setMyPageDropdownOpen] = useState(false);
  const timeoutRef = useRef<number | undefined>(undefined);

  // 로컬 스토리지 변경 감지
  useEffect(() => {
    const handleStorageChange = () => {
      setOnline(!!Cookies.get("token"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleCustomerSupportMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setCustomerSupportDropdownOpen(true);
  };

  const handleCustomerSupportMouseLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setCustomerSupportDropdownOpen(false);
    }, 100); // 0.1초의 딜레이
  };

  const handleMyPageMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setMyPageDropdownOpen(true);
  };

  const handleMyPageMouseLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setMyPageDropdownOpen(false);
    }, 100); // 0.1초의 딜레이
  };

  const handleLogout = async () => {
    const socialLogin = new SocialLogin("logout"); // 적절한 소셜 타입을 전달
    await socialLogin.logout();
    window.location.replace("/login");
  };

  return (
    <header className="bg-brand p-2">
      <div className="relative flex justify-between max-w-screen-2xl mx-auto w-4/5">
        <nav className="flex items-baseline gap-x-12 font-semibold p-4">
          <Link to="/" className="flex items-baseline gap-x-2 text-4xl text-gray-50">
            <FaVideo className="self-end" />
            <span className="font-bold">Poten</span>
          </Link>
          <div className="hidden lg:flex lg:gap-x-16">
            <Link to="/" className="text-xl font-semibold text-gray-50">
              About Us
            </Link>
            <Link to="/market" className="text-xl font-semibold text-gray-50">
              마켓
            </Link>
            <div
              className="relative"
              onMouseEnter={handleCustomerSupportMouseEnter}
              onMouseLeave={handleCustomerSupportMouseLeave}
            >
              <button className="text-xl font-semibold text-gray-50">
                고객 지원
              </button>
              {customerSupportDropdownOpen && (
                <div
                  className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10"
                  onMouseEnter={handleCustomerSupportMouseEnter}
                  onMouseLeave={handleCustomerSupportMouseLeave}
                >
                  <Link to="/notice" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                    공지사항
                  </Link>
                  <Link to="/FAQ" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                    자주 묻는 질문
                  </Link>
                  <Link to="/QandAForm" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                    문의하기
                  </Link>
                </div>
              )}
            </div>
          </div>
        </nav>
        <div
          className="flex items-baseline gap-x-4 font-semibold p-4 relative"
          onMouseEnter={handleMyPageMouseEnter}
          onMouseLeave={handleMyPageMouseLeave}
        >
          <FaSistrix className="text-4xl text-gray-50" />
          {online ? (
            <div
              className="relative"
              onMouseEnter={handleMyPageMouseEnter}
              onMouseLeave={handleMyPageMouseLeave}
            >
              <button className="text-xl font-semibold text-gray-50">
                My Page
              </button>
              {myPageDropdownOpen && (
                <div
                  className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10"
                  onMouseEnter={handleMyPageMouseEnter}
                  onMouseLeave={handleMyPageMouseLeave}
                >
                  <Link to="/mypageCh" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                    보유 채널
                  </Link>
                  <Link to="/mypagePoint" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                    포인트 현황
                  </Link>
                  <Link to="/mypageProfit" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                    실현 손익
                  </Link>
                  <Link to="/mypageTradeEdit" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                    내 거래 관리
                  </Link>
                  <Link to="/infoModify" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                    개인정보 수정
                  </Link>
                  <Link to="/infoafter" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                    개인 채널 정보
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    로그아웃
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link to="/Login" className="text-xl font-semibold text-gray-50">
              로그인
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
